.ol-attribution {
  right: auto;
  left: 0.5em;
}

.map-tooltip {
  padding: 3.5px;
  background-color: rgba(64, 176, 211, 0.7);
  font-weight: 600;
}
