.contentGrid {
  @extend %readingWidth;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: $pageTitleColumn auto;
  grid-template-columns: repeat(4, 1fr);
  @include smDown {
    padding: 0 15px;
  }
  @include smUp {
    grid-template-columns: repeat(8, 1fr);
  }
  .pageTitle {
    grid-column: 1/-1;
    width: 100%;
  }
  .contentHead {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .pubContentSection,
  .contentHead,
  .pubEntry {
    grid-column: 1/-1;
    @include smUp {
      grid-column: 2/-3;
    }
  }
  .sideBar {
    grid-column: -3/-1;
    grid-row: 1 / span 10;
  }
}
.publikationen .sideBar {
  grid-row: 2 / span 10;
}
