.fsMap {
  @extend %cardDesignBeta;
  position: relative;
  .btn {
    position: absolute;
    z-index: 10;
    bottom: 10px;
    right: 20px;
  }
}
