.glacierData {
  background-color: $txtInvert;
  margin-top: 30px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  @extend %boxShadow;
  .singleValue {
    width: 100%;
  }
  .hint {
    width: 100%;
  }
}
