.fsMeasurements {
  @extend %cardDesignAlpha;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  .cardTitle {
    margin: 0;
    width: 100%;
    @extend %fontL;
    font-weight: 700;
    padding-bottom: 20px;
  }
}
