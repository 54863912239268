$ieColumnsDesktop: 20px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 280px 20px;

.internetExplorer {
  &.ie11,
  &.ie10,
  &.ie9 {
    //lets do this
    .oldIE {
      background-color: $glamosColor;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 50px;
      padding-top: 100px;
      text-align: center;
    }
    .navGrid {
      display: -ms-grid;
      -ms-grid-columns: $ieColumnsDesktop;
      border-bottom: solid 1px $lightBorder;
      img {
        width: 100px;
      }
      .glamosBrand {
        -ms-grid-column: 2;
        -ms-grid-column-span: 2;
      }
      .navMain {
        -ms-grid-column: 5;
        -ms-grid-column-span: 6;
      }
      .navLang {
        -ms-grid-column: 11;
        -ms-grid-column-span: 4;
      }
    }
    //gridMapViewer
    .gridMapViewer {
      display: -ms-grid;
      -ms-grid-columns: 1fr 300px;
      -ms-grid-rows: 1fr 1fr;
      @include smUp {
        height: 100vh;
      }
    }
    .mapContainer {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }
    .outputContainer {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }
    .sidebarContainer {
      -ms-grid-column: 2;
      -ms-grid-row-span: 2;
    }
    .indexGrid {
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      -ms-grid-rows: 70vh 20vh auto auto;
      //-ms-grid-rows: 1fr 1fr 1fr;
      display: -ms-grid;
      @include smUp {
        -ms-grid-rows: 140px 490px 40px auto auto;
        -ms-grid-columns: $ieColumnsDesktop;
        height: 100vh;
      }
      .indexHead {
        -ms-grid-column-span: 13;
      }
      .indexMap {
        -ms-grid-column-span: 13;
        @include smUp {
          -ms-grid-row-span: 2;
          -ms-grid-row: 2;
        }
      }
      .mapMetadata {
        -ms-grid-column-span: 1;
        -ms-grid-row: 2;
        z-index: 100;
        position: relative;
        @include smUp {
          -ms-grid-column: 12;
        }
      }
      .indexOverview {
        -ms-grid-column-span: 13;
        -ms-grid-row: 4;
        display: flex;
        .majorLink {
          width: 25%;
          padding: 10px 0;
          padding-left: 20px;
        }
      }
      .socialMedia {
        -ms-grid-column-span: 1;
        -ms-grid-row: 4;
        @include smUp {
          -ms-grid-column: 12;
          -ms-grid-row: 3;
        }
      }
      .welcomeText {
        -ms-grid-column-span: 10;
        -ms-grid-column: 2;
        -ms-grid-row: 5;
      }
    }
    .footer {
      display: -ms-grid;
      -ms-grid-columns: $ieColumnsDesktop;
      width: 100%;
      > div {
        -ms-grid-column-span: 8;
      }
    }
    .factsheetGrid {
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      -ms-grid-rows: 20vh auto 300px auto auto auto auto;
      .pageTitle {
        -ms-grid-column: 2;
        -ms-grid-column-span: 5;
        -ms-grid-row: 1;
      }
      .fieldSearchWrapper {
        -ms-grid-column: 6;
        -ms-grid-column-span: 2;
        -ms-grid-row: 1;
      }
      .fsMap {
        -ms-grid-column-span: 5;
        -ms-grid-row: 2;
        margin-bottom: 20px;
        margin-right: 10px;
        border: solid 1px $lightBorder;
      }
      .fsMeasurements {
        -ms-grid-column: 6;
        -ms-grid-column-span: 2;
        -ms-grid-row: 2;
        margin-left: 10px;
        border: solid 1px $lightBorder;
        margin-bottom: 20px;
      }
      .fsExport {
        -ms-grid-column: 8;
        -ms-grid-column-span: 1;
        -ms-grid-row: 2;
        margin-left: 10px;
        margin-bottom: 20px;
        border: solid 1px $lightBorder;
      }
      .factsheetChartBox {
        -ms-grid-row: 3;
        -ms-grid-column-span: 8;
        .fsChart {
          border: solid 1px $lightBorder;
        }
      }
      .fsPhoto {
        -ms-grid-row: 4;
        -ms-grid-column-span: 2;
      }
      .fsComment {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
        -ms-grid-column-span: 6;
      }
    }
    .pubContentSection {
      width: 80%;
      float: left;
    }
    .anchorNav {
      width: 18%;
      float: right;
    }
  }
}
