.sidebarContainer {
  height: fit-content; // fix GLAMOS-337
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $secondaryColor;
  .sidebarControls {
    //background-color: red;
  }
  .latestMeasurements {
    //background-color: blue
  }
}
.content .sidebarContainer {
  @extend %hiddenSmDown;
}

//this is the styling for the mobile drawer
.mobileMapInput {
  .sidebarContainer {
    padding: 0;
    background-color: transparent;
  }
}
