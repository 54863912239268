.footer {
  @extend %readingWidth;
  display: flex;
  padding-bottom: 50px;
  padding-top: 40px;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: space-between;
    grid-column: 1/-1;
    @include smUp {
      grid-column: 3/-2;
    }
  }
  @include smDown {
    padding: 0 15px;
  }
  ul {
    @extend %verticalList;
    @extend %fontM;
    list-style-type: none;
    li {
      padding-right: 10px;
    }
  }
}
.homepage {
  .footer {
    display: grid;
    max-width: 100%;
    padding: 0;
    @extend %horizontalGrid;
    @include smDown {
      padding: 0 15px;
    }
  }
}
