.indexOverview {
  @include smDown {
    padding: 15px;
    padding-left: 20px;
  }
  @include smUp {
    display: grid;
  }
  @extend %horizontalGrid;
  .majorLink {
    @include smUp {
      &:nth-child(2n + 1) {
        grid-column: 3/6;
      }
      &:nth-child(2n + 2),
      &:nth-child(2n + 3) {
        grid-column: span 3;
      }
    }
  }
}
