.latestMeasurements {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .btnTxt {
    padding-bottom: 10px;
  }
  .latestMeasurementsHead {
    width: 100%;
  }
  .hint {
    color: $txtInvert;
  }
}

//mobile sidebar drawer
.mm-panel {
  .sidebarContainer {
    .latestMeasurements {
      align-items: center;
    }
  }
}
