.navBar {
  a {
    text-decoration: none;
    &:hover,
    .activeLang a {
      color: $linkColor;
      text-decoration: underline;
    }
  }
  .navTitle {
    display: block;
    color: $txtInvert;
    h1 {
      margin: 0;
      text-transform: uppercase;
      @extend %fontM;
      font-weight: 700;
    }
  }
}
.navMain {
  @extend %fontM;
  @extend %verticalList;
}
.navLang {
  @extend %fontM;
  @extend %verticalList;
}
@include smUp {
  .homepage .navLang {
    padding-left: 20px;
  }
}
#mainMobileNav:not(.mm-menu),
#navMapViewer:not(.mm-menu) {
  display: none;
}
//detail styling
.navMain,
.navLang {
  height: 100%;
  li {
    height: 100%;
    > a {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0 5px;
      @include mdUp {
        padding: 0 10px;
      }
      color: $txtColor;
    }
    &.active,
    &.activeLang,
    a:hover {
      background-color: $txtInvert;
      text-decoration: none;
      color: $txtColor;
    }
  }
}
//index styling
.navIndex {
  .navMain,
  .navLang {
    li a {
      border-bottom: solid 4px white;
    }
    > li {
      &.active a,
      &.activeLang a,
      a:hover {
        text-decoration: none;
        border-bottom: solid 4px $glamosColor;
      }
    }
  }
}
//mobile navi
.mm-wrapper_opened {
  .mm-page {
    @extend %boxShadow;
  }
}
.mm-menu {
  background: $lightBorder;
  a {
    @extend %fontM;
  }
  .active,
  .activeLang {
    background-color: $glamosColor;
    &.mm-listitem:after {
      border: none;
    }
  }
}

/* Handles to switch sidebar content (and open for mobile) */
#sidebarSwitcher {
  display: grid; // create nested grid
  grid-template-rows: min-content min-content min-content min-content min-content;
  /* justify-content is replaced by justify-self on .attached-left|right */
  grid-row: 1/1;
  grid-column: 1/-1;
  @include smUp {
    grid-column: 1/-3; // skip sidebar
  }
}

#sidebarSwitcher > * {
  position: relative;
  // note: don't set z-index on #sidebarSwitcher since it would inhibit click through to underlaying
  z-index: 10;
}

#sidebarSwitcher {
  /* .exclusiveToggle needs to be the first of its siblings.
   * Either is is shown alone (think exclusively) or all others are shown
   */

  // default for desktop
  .exclusiveToggle {
    display: none;
  }

  @include mdDown {
    // all non-.exclusiveToggle's
    .navSidebarToggle {
      display: none;
    }
    .exclusiveToggle.active ~ .navSidebarToggle {
      display: inherit;
    }

    // .exclusiveToggle itself
    .exclusiveToggle {
      display: inherit;
    }
    .exclusiveToggle.active {
      display: none;
    }
  }
}
