.downloadGrid {
  @extend %readingWidth;
  @extend %heightViewport;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: $pageTitleColumn auto;
  grid-template-columns: repeat(4, 1fr);
  @include smDown {
    padding: 0 15px;
  }
  @include smUp {
    grid-template-columns: repeat(8, 1fr);
  }
  .pageTitle {
    grid-column: 1/-1;
  }
  .tabContainer {
    grid-column: 1/-1;
  }
}
