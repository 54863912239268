$glamosColor: #45cadd;
$secondaryColor: #d1f9ff;
$lightBorder: #efefef;

$txtColor: rgba(0, 0, 0, 0.75);
$txtInvert: white;
$txtSecColor: grey;
$shadowColor: rgba(#303030, 0.3);
$linkColor: pink;

$markerColor: #a52a2a;
$markerDisabledColor: rgba(163, 163, 163, 0.7);

@mixin fontSize($sizeValue: 1, $lhValue: 1, $lsValue: 0, $wsValue: 0) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
  line-height: $lhValue;
  font-feature-settings: 'kern' 1;
  letter-spacing: $lsValue + em;
  word-spacing: $wsValue + em;
  -webkit-font-smoothing: antialiased; //for osx
  -moz-osx-font-smoothing: grayscale; // for osx
  font-weight: normal;
}

@mixin fade($fadeValue: color) {
  transition: $fadeValue 0.3s linear;
  -webkit-transition: $fadeValue 0.3s linear;
  -moz-transition: $fadeValue 0.3s linear;
}
body {
  background-color: #fff;
}
%hyphenate {
  hyphens: auto;
}
%verticalList {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  //justify-content: space-between;
}
%mobilePadding {
  @include smDown {
    padding: 0 20px;
  }
}
%boxShadow {
  -webkit-box-shadow: 2px 1px 3px 0 $shadowColor;
  -moz-box-shadow: 2px 1px 3px 0 $shadowColor;
  -ms-box-shadow: 2px 1px 3px 0 $shadowColor;
  box-shadow: 2px 1px 3px 0 $shadowColor;
}

@mixin boxShadow {
  -webkit-box-shadow: 2px 1px 3px 0 $shadowColor;
  -moz-box-shadow: 2px 1px 3px 0 $shadowColor;
  -ms-box-shadow: 2px 1px 3px 0 $shadowColor;
  box-shadow: 2px 1px 3px 0 $shadowColor;
}

html,
*,
body {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img {
  width: 100%;
}
