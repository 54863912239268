.outputContainer {
  @extend %mobilePadding;
  display: flex;
  flex-direction: column;
  @include smUp {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .chartControls {
    display: flex;
    @extend %fontM;
    justify-content: space-between;
    flex-direction: column;
    @include smUp {
      align-items: center;
      flex-direction: row;
    }
  }
}
.chartContainer {
  //background-color: #654;
  flex: 1;
  .outputChart {
    width: 100%;
    height: 100%;
  }
}
