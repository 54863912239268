$viewportMaxHeight: 568px;

//this is the horizontal grid for all grids so it matches the navigation (except factsheet)
%horizontalGrid {
  grid-template-columns: repeat(4, 1fr);
  @include smUp {
    grid-template-columns: 20px repeat(10, 1fr) minmax(200px, 1fr) 20px;
  }
  @include mdUp {
    grid-template-columns: 20px repeat(10, 1fr) minmax(300px, 1fr) 20px;
  }
}
// this makes the grid layout full height until the vieport is a certain size
%heightViewport {
  @media (min-height: $viewportMaxHeight) {
    min-height: calc(100vh - 50px);
    margin-top: 50px;
  }
}

//this creates a row rule for the pages with a page pageTitle
$pageTitleColumn: minmax(min-content, 20vh);

//creates acontainer wich is not too wide for better reading
%readingWidth {
  padding: 0 15px 30px 15px;
  @include smUp {
    max-width: 960px;
    margin: auto;
    padding: 0 20px;
  }
  @include lgUp {
    max-width: 1200px;
  }
}

.content {
  margin-top: 50px;
}
.homepage .content {
  @include smUp {
    margin-top: 0;
  }
}
