.factsheetGrid {
  @extend %readingWidth;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  @include smDown {
    padding: 0 15px;
    grid-template-rows: auto auto minmax(300px, 35vh) auto;
  }
  @include smUp {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: $pageTitleColumn auto;
  }
  .pageTitle {
    grid-column: 1/-1;
    @include smUp {
      grid-column: span 6;
      align-self: flex-end;
    }
  }
  .fieldSearchWrapper {
    grid-column: 1/-1;
    @include smUp {
      grid-column: span 2;
    }
  }
  .fsMap {
    grid-column: 1/-1;
    @include smUp {
      grid-column: span 6;
    }
  }
  .fsMeasurements {
    grid-column: 1/-1;
    @include smUp {
      grid-column: span 2;
    }
  }
  .fsPhoto {
    grid-column: 1/-1;
    @include smUp {
      grid-column: span 3;
    }
  }
  .fsComment {
    grid-column: 1/-1;
    @include smUp {
      grid-column: span 2;
    }
    &.cardCommentBig {
      @include smUp {
        grid-column: span 5;
      }
    }
    &.cardCommentFull {
      grid-column: 1/-1;
    }
    > .fsCommentTitle {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .fsQuotation {
    grid-column: 1/-1;
  }
  .fsExport {
    @extend %hiddenMdDown;
  }
  .fsChart {
    grid-column: 1/-1;
  }
}
