.fieldSearchWrapper {
  display: flex;
  align-items: center;
  @extend %mobilePadding;
  input {
    border-radius: 40px 0 0 40px;
    background-color: lighten($glamosColor, 30%);
    padding-left: 20px;
    min-width: 0;
    height: 30px;
    @extend %fontM;
    border: solid 1px $lightBorder;
    @include mdDown {
      max-width: 100px;
    }
    @include smDown {
      max-width: 100%;
      flex: 1;
    }
  }
  ::-moz-placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder,
  ::placeholder {
    color: $markerColor;
    padding-left: 20px;
  }
  button {
    border: none;
    height: 30px;
    background-color: $markerColor;
    color: $txtInvert;
    border-radius: 0 40px 40px 0;
    margin: 0;
  }
}

//mobile sidbar drawer
.mm-panel {
  .sidebarContainer {
    .fieldSearchWrapper {
      padding: 0;
      input {
        width: 60%;
      }
    }
  }
}

.ui-autocomplete.ui-widget {
  // lower grid row is: minmax( (300px+250px), (58vh+1fr) )
  // position of bottom-of-searchbar from top edge is: 50+20+30 = 100
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
