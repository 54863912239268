.gridMapViewer {
  display: grid;
  @extend %heightViewport;
  @extend %horizontalGrid;
  grid-template-rows: minmax(auto, 55vh) auto;
  @include smUp {
    // min for both rows is 350px
    // first row max: 100% viewport height - 50px menu bar - 350px chart zone height
    grid-template-rows: minmax(350px, calc(100vh - 50px - 350px)) 350px;
  }
}
.mapContainer {
  background-color: #fff;
  grid-column: 1/-1;
  grid-row: 1/2;
}
.outputContainer {
  grid-column: 1/-1;
  grid-row: -2/-1;
  //background-color: yellow;
  @include smUp {
    grid-column: 2/-3;
  }
}
.sidebarContainer {
  /* sidepane is layered above map (+chart), coverint it on mobile */
  z-index: 5;
  grid-column: 1/-1;
  grid-row: 1/-1;
  @include smUp {
    // Fix visual BUG (map beneath sidebar) introduced by bugfix of discussed bug in GLAMOS-345
    height: 100%;
    grid-column: -3/-1;
  }
}

.gridMapViewer {
  /* on mobile, sidebar shall cover up full width */
  .sidebarContainer {
    @include mdDown {
      display: none;
      &.active {
        display: flex !important; //TODO: replace .sidebarContainer by something not in gugus
      }
    }
  }

  /* toggle visibility of different sidebar content blocks */
  .sidebarContainer > * {
    display: none;
    &.active {
      display: block;
    }

    // show schluesseldaten anyway
    &.latestMeasurements {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      padding-top: 25px;
    }
  }
}
