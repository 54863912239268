.singleValue {
  width: 100%;
  border-bottom: solid 4px $glamosColor;

  p {
    @extend %fontXXL;
    font-weight: 700;
    text-align: right;
    line-height: 1; // override fontXXL line-height
  }
  small {
    @extend %fontM;
    display: block;
    font-weight: 600;
  }
  .singleValueLabels {
    display: flex;
    justify-content: space-between;
  }
}
.singleValue.first {
  margin-top: 1em;
  border-top: solid 4px $glamosColor;
}
