// lightgallery plugins backdrop

.lg-backdrop {
  background-color: rgba(darken($glamosColor, 20), 0.6);
}

.lg-toolbar .lg-icon {
  color: white;
  font-size: 35px; // download & close icon size
}

#lg-counter {
  @extend %fontL;
  color: white;
  font-weight: 700;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  font-size: 50px; // next & previous arrow size
  color: white;
  // same color as used in base lib in legend & toolbar
  background-color: rgba(0, 0, 0, 0.45);
}

.lg > .lg-sub-html {
  font-size: 24px; //legend text
}

.lg-outer .lg-img-wrap {
  max-width: 800px;
  margin: auto;
  max-height: 500px;
}
//hack for jumpy fixed navbar on opening the mobile menu
.mm-wrapper_opened {
  .navBar {
    position: relative;
  }
  .content,
  .gridMapViewer {
    margin-top: 0;
  }
}
