.titleCrosslink {
  margin-left: 10px;
  position: absolute;
  text-decoration: none;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  @include smUp {
    top: -20px;
    right: 0;
  }
  &:hover {
    svg circle {
      fill: darken($markerColor, 20%);
    }
  }
  svg circle {
    fill: $markerColor;
  }
}

//mobile sidebar drawer
.mm-panel {
  .sidebarContainer {
    .titleCrosslink {
      right: 0;
      top: -10px;
    }
  }
}
