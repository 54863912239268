ul.tabLinks {
  margin: 0px;
  padding: 0px;
  list-style: none;
  @include smUp {
    display: flex;
  }
  & li {
    @extend %fontM;
    a {
      color: #222;
      background: $lightBorder;
      display: block;
      text-decoration: none;
      padding: 10px 15px;
      &.current,
      &:hover {
        background: $glamosColor;
        color: $txtInvert;
        font-weight: 700;
      }
    }
  }
}
.tabPanel {
  display: none;
  background: white;
  border: solid 2px $glamosColor;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 50vh;
  @include smUp {
    columns: 300px 3;
    .downloadEntry {
      break-inside: avoid;
      page-break-inside: avoid;
      min-height: 100px;
    }
    p {
      margin-top: 0;
    }
  }
  &.current {
    display: inherit;
    @extend %boxShadow;
  }
}
