%fontXXL {
  @include fontSize(2.5, 1.2);
  @include smUp {
    @include fontSize(3.5, 1.3);
  }
  margin: 0;
}
%fontXL {
  @include fontSize(1.7, 1.2);
  @include smUp {
    @include fontSize(1.8, 1.2);
  }
  margin: 0;
}
%fontL {
  @include fontSize(1.6, 1.2);
  @include smUp {
    @include fontSize(1.5, 1.2);
  }
}
%fontM {
  @include fontSize(1, 1.35);
  @include smUp {
    @include fontSize(1, 1.4);
  }
}
%fontS {
  @include fontSize(0.8, 1.2);
}
%fontXS {
  @include fontSize(0.7, 1.2);
}

@font-face {
  font-family: 'lg';
  src: url('./webfonts/lg.eot?n1z373');
  src: url('./webfonts/lg.eot?#iefixn1z373') format('embedded-opentype'),
    url('./webfonts/lg.woff?n1z373') format('woff'),
    url('./webfonts/lg.ttf?n1z373') format('truetype'),
    url('./webfonts/lg.svg?n1z373#lg') format('svg');
  font-weight: normal;
  font-style: normal;
}
