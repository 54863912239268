$burgerSize: 3px;
$gapSize: 3px;

.navMobileToggle {
  height: 100%;
  a {
    color: $txtColor;
    display: block;
    @extend %fontM;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $markerColor;
    }
  }
}

//zweites mmenu für map viewer einstellungen
.navMapViewerToggle {
  @extend %hiddenSmUp;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  background-color: $glamosColor;
  background-image: url('../img/magnifier.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50% 50%;
  width: 44px;
  height: 44px;
  &:hover {
    opacity: 0.5;
  }
}
//hack for when drawer is opened. prevents link from jumping.
.mm-wrapper_opened {
  .navMapViewerToggle {
    top: 60px;
  }
}

.navSidebarToggle {
  margin: 3px;
  border-radius: 50%;
  background-color: $glamosColor;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50% 50%;
  width: 44px;
  height: 44px;

  /* left/right type: just style, no layout */

  &.attached-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    justify-self: left;
  }

  &.attached-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    justify-self: right;
  }

  /* states */

  &:hover {
    opacity: 0.5;
  }

  &.active {
    /*
     * don't highlight on mobile, since button active is hidden anyway except at initial
     * loading, two of them are .active whereas the sidebar related one is shown
     * (in mobile: we want to show the map; on desktop (map+)sidepane)
     */
    @include mdUp {
      background-color: $markerColor;
    }
  }

  /* per individual toggle specificities */

  &[data-tab='mapContainer'] {
    background-image: url('../img/close.svg');
  }

  &[data-tab='comparisonContainer'] {
    background-image: url('../img/magnifier.svg');
  }

  &[data-tab='layerSwitcher'] {
    background-image: url('../img/layer-switch.svg');
    &.active {
      background-image: url('../img/layer-switch-inverted.svg');
    }
  }
}
