/* adapt style of ol-ext (OpenLayers Extensions)' LayerSwitcher */

.ol-layerswitcher.ol-control {
  display: none;
}

.ol-layerswitcher .layerup {
  height: 1.5em;
}

.ol-layerswitcher .panel li label {
  font-size: small;
  font-family: 'Barlow Semi Condensed', serif;
  max-width: 100%;
}

.ol-layerswitcher .panel .ol-layer-tile label {
  align-items: center;
  display: inline-flex;
  max-width: 100%;
}

.ol-layerswitcher .layerswitcher-opacity {
  display: none;
}

.ol-layerswitcher .panel li > div {
  display: inline-block;
  position: relative;
  float: none;
  min-width: 1.3em;
}

.ol-layerswitcher .expend-layers::after {
  background: #2b7bb9;
}

.ol-layerswitcher .expend-layers::before,
.ol-layerswitcher .collapse-layers::before {
  background: #2b7bb9;
}

.ol-layerswitcher [type='checkbox']:checked + label::after {
  border-color: #2b7bb9;
  background: transparent;
  box-shadow: 1px 0 1px 1px transparent;
}

.ol-layerswitcher [type='checkbox'] + label::before {
  border-color: #2b7bb9;
  background: transparent;
}

.ol-layerswitcher [type='radio']:checked + label::after {
  background: #2b7bb9;
  border: 2px solid #2b7bb9;
}

.ol-layerswitcher [type='radio'] + label::before {
  background: transparent;
}

.ol-layerswitcher .expend-layers::before,
.ol-layerswitcher .collapse-layers::before {
  top: 25%;
  margin-left: -8px;
}

.ol-layerswitcher .expend-layers::after {
  top: -25%;
  margin-left: -10px;
}

.ol-layerswitcher .colorlegend {
  height: 3px;
  margin-right: 0.5em;
  width: 3em;
}

.ol-layerswitcher .legend-icon {
  height: 90%;
  margin-right: 0.2em;
  width: auto;
  display: inline-block;
}

.ol-layerswitcher .legend-custom-image {
  display: block;
  margin: 0.2em 0 0.2em 1em;
}
