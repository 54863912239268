body {
  font-family: 'Barlow Semi Condensed', sans-serif;
  @extend %fontM;
}
.homepage {
  .navBar {
    @extend %hiddenSmUp;
  }
}
.hint {
  @extend %fontS;
  color: $txtSecColor;
}
.pageTitle {
  @extend %fontXXL;
  font-weight: 700;
  align-self: center;
  @extend %hyphenate;
  line-height: 1;
}
.sectionTitle {
  @extend %fontXL;
  font-weight: 700 !important;
  border-bottom: solid 4px $glamosColor;
  padding-bottom: 10px;
}
.scientificTitle,
.pubEntryTitle {
  @extend %fontM;
  font-weight: 700 !important;
  margin-bottom: 0;
}
h2.currentGlacier {
  position: relative;
  width: 100%;
  @extend %fontXL;
  font-weight: 700 !important;
  padding-bottom: 20px;
}
h1.currentGlacier {
  position: relative;
  @extend %fontXL;
  font-weight: 700 !important;
}

p,
li,
ul {
  @extend %fontM;
}
ul {
  padding-left: 15px;
}
a {
  color: $markerColor;
  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: none;
}

.warn {
  color: red;
}

.toast {
  background-color: #fe8;
}
