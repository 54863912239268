.majorLink {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  @include smDown {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  @include smUp {
    flex-direction: column;
  }
  p {
    @extend %fontXL;
    font-weight: 700;
  }
  small {
    @extend %fontM;
    display: block;
    font-weight: 700;
    @include smDown {
      order: 2;
      width: 50%;
    }
  }
  a {
    align-self: flex-start;
    @include smDown {
      min-width: 50%;
    }
  }
}
