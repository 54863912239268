.indexHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $txtInvert;
  @extend %boxShadow;
  img {
    width: 150px;
  }
  h1 {
    text-transform: uppercase;
    @extend %fontXXL;
    font-weight: 700;
    color: $glamosColor;
  }
  .navIndex {
    display: flex;
    justify-content: center;
  }
}
