.factsheetListing {
  .pageTitle {
    display: grid;
    grid-template-columns: 7fr 3fr;
    @include smDown {
      /* Once the search bar is no longer on the same row, also split rows for download & title */
      grid-template-columns: 1fr;
    }
  }

  .pageTitleHeading {
    font-size: 3.5rem;
    font-weight: 400;
    margin: 0;
  }

  .titleLabel {
    display: block;
    @extend %fontL;
    font-weight: 700;
    padding-top: 20px;
  }

  .fieldSearchWrapper {
    align-items: flex-end;
    padding-bottom: 10px;
  }

  .downloadBtnContainer {
    justify-self: end;
    align-self: end;
    @include smDown {
      /* This makes it align with the search button */
      padding: 0 20px;
    }
  }
}
