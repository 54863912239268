.indexGrid {
  display: grid;
  @extend %horizontalGrid;
  grid-template-rows: auto 70vh auto;
  @include smUp {
    grid-template-rows:
      minmax(140px, 20vh) minmax(480px, 50vh) 40px minmax(150px, 10vh)
      auto;
  }
}

.indexHead {
  grid-column: 1/-1;
  @extend %hiddenSmDown;
}
.indexMap {
  background-color: $glamosColor;
  grid-column: 1/-1;
  @include smUp {
    grid-row: 2/4;
  }
}
.mapMetadata {
  //background-color: yellow;
  padding-top: 30px;
  position: relative;
  grid-column: 1/-1;
  z-index: 10;
  @include smUp {
    grid-column: -3/-2;
    grid-row: 2/3;
  }
}
.indexOverview {
  background-color: $glamosColor;
  grid-column: 1/-1;
  @extend %boxShadow;
  @include smUp {
    grid-row: 4;
  }
}
.socialMedia {
  //background-color: #476;
  grid-column: 1/-1;
  @extend %mobilePadding;
  margin-top: 20px;
  position: relative;
  z-index: 100;
  .twitterFeed {
    @extend %hiddenSmDown;
  }
  .twitterDirect {
    display: block;
    text-align: center;
    @extend %hiddenSmUp;
  }
  iframe {
    @extend %boxShadow;
  }
  @include smUp {
    grid-column: -3/-2;
    grid-row: 4/-1;
  }
}
.welcomeText {
  //background-color: #478;
  padding-top: 20px;
  padding-bottom: 50px;
  grid-column: 1/-1;
  @extend %mobilePadding;
  @include smUp {
    grid-column: 3/-4;
  }
}

//remove standard navbar from homepage
.homepage {
  .indexGrid {
    margin-top: 0;
    min-height: 80vh;
  }
}
