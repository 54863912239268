@include smUp {
  .dropDown {
    position: relative;
    z-index: 10000;
    ul {
      display: block;
      padding-left: 0;
      position: absolute;
      list-style-type: none;
      background-color: white;
      @include boxShadow;
      border: solid 1px $lightBorder;
      li {
        a {
          padding: 5px 10px;
          &:hover {
            border: none;
            background-color: $glamosColor;
          }
        }
      }
    }
    &:hover {
      > ul {
        display: block;
      }
    }
    > ul {
      display: none;
    }
  }
}
