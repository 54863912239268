.matrixGridPage {
  $grid-columns-max: 8;

  img {
    margin-top: 16px;
  }
  .column {
    @for $i from 1 through $grid-columns-max {
      &#{$i} {
        grid-column: span $grid-columns-max;

        @include smUp {
          grid-column: span $i;
        }
      }
    }
  }
  .offset {
    @for $i from 1 through $grid-columns-max {
      &#{$i} {
        grid-column: span $i;
      }
    }
  }
  .offsetPusher {
    min-height: 10px;
    visibility: hidden;
    @extend %hiddenSmDown;
    &.offset0 {
      display: none;
    }
  }
}
