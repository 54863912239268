.comparisonControlWrapper {
  padding-top: 20px;
}
.comparisonEntry {
  border-bottom: solid 1px $txtInvert;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  .glacierName {
    background-color: transparent;
    border: none;
    display: inline;
    width: 100%;
    text-align: left;
    @extend %fontM;
  }
  .close {
    width: 20px;
    height: 20px;
    padding: 0;
    svg {
      padding: 5px;
      width: 100%;
    }
  }
  &.active:before,
  &:hover:before {
    content: '>';
  }
  &:hover {
    cursor: pointer;
  }
  > button {
  }
}
