.btn {
  background-color: $markerColor;
  color: $txtInvert;
  text-decoration: none;
  padding: 4px 15px;
  border-radius: 40px;
  display: inline-block;
  margin: 10px 0;
  border: none;
  @extend %fontM;
  &:hover {
    background-color: darken($markerColor, 20%);
    @extend %boxShadow;
    cursor: pointer;
  }
  &:disabled {
    background-color: $markerDisabledColor;
    &:hover {
      background-color: darken($markerDisabledColor, 20%);
    }
  }
}
.btnTxt {
  @extend %fontM;
  color: $markerColor;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}
.btnSmall {
  @extend %fontS;
}

.btnGroup {
  .btn:first-child {
    border-radius: 20px 0 0 20px;
  }
  .btn:last-child {
    border-radius: 0 20px 20px 0;
  }
}
