.navBar {
  background-color: $glamosColor;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  @extend %boxShadow;
  .navGrid {
    height: 50px;
    display: grid;
    align-items: center;
    @extend %horizontalGrid;
    .glamosBrand {
      grid-column: span 3;
      @include smUp {
        grid-column: 2/5;
      }
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        max-width: 40px;
        @include smUp {
          max-width: 100px;
        }
        &.desktop {
          @extend %hiddenMdDown;
        }
        &.mobile {
          @extend %hiddenSmUp;
        }
      }
    }
    .navMobileToggle {
      grid-column: 1;
      @include smUp {
        grid-column: 4/8;
      }
      @extend %hiddenSmUp;
    }
    .navMain {
      @include smUp {
        grid-column: 5/12;
      }
      @include lgUp {
        grid-column: 6/12;
      }
      @extend %hiddenSmDown;
    }
    .navLang {
      @extend %hiddenSmDown;
    }
  }
}
