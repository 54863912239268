%cardDesignAlpha {
  @extend %boxShadow;
  padding: 15px;
  background-color: $txtInvert;
  border: solid 1px $shadowColor;
}
%cardDesignBeta {
  @extend %boxShadow;
  background-color: $txtInvert;
  border: solid 1px $shadowColor;
}
